import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("jquery")
require("@nathanvda/cocoon")

import "bootstrap"
import "../stylesheets/application"
import "animate.css"


const WOW = require('wowjs');

$(document).on("change", "#status.galleries_status", function () {
  let selected_status = $(this).val().toLowerCase();
  let url = $(this).attr("data-controller");
  $.ajax({
    url: url,
    dataType: 'script',
    method: "GET",
    data: { status: selected_status }
  })
});


$(document).on('turbolinks:load', function () {
  // scroll top of the pagge. Rewriting turbolinks behaviour
  setTimeout(() => { window.scrollTo(0, 0) }, 100);
  
  var wow = new WOW.WOW(
    { 
      live: false,
      boxClass:     'wow',      // animated element css class (default is wow)
      animateClass: 'animated', // animation css class (default is animated)
      offset:       0,          // distance to the element when triggering the animation (default is 0)
      mobile:       true,       // trigger animations on mobile devices (default is true)    // act on asynchronously loaded content (default is true)
      callback:     function(box) {
        if ( $('#nakrutka_1').length ){
          animateValue("nakrutka_1", 0, 6, 3000);
          animateValue("nakrutka_2", 0, 250, 3000);
          animateValueCustom("nakrutka_3", 0, 18000, 2000);
          animateValue("nakrutka_4", 0, 980, 100);
          animateValue("nakrutka_5", 0, 233, 3000);
          animateValue("nakrutka_6", 0, 33, 3000);
        }
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null // optional scroll container selector, otherwise use window
    }
  );

  document.wow = wow;
  wow.init();

  $(document).scroll(function () {
    if ($(window).scrollTop() > 0) {
      $('.header').addClass('nav-animation')
    } else {
      $('.header').removeClass('nav-animation')
    }
  });


  $('.validateContactform').keyup(function () {
    styleContactSubmitbutton(isContactValid());
  });

});

function styleContactSubmitbutton(isValidated) {
  let submit_button = document.getElementById("btn-contact");

  if (isValidated) {
    submit_button.disabled = false;
    submit_button.style.backgroundColor = "#000", submit_button.style.color = "#fff";
  }  else {
    if (submit_button != null ) {
      submit_button.disabled = true;
      submit_button.style.backgroundColor = "#f3f5fd", submit_button.style.color = "#495361";
    }
  } 
};

styleContactSubmitbutton(isContactValid());

function isContactValid() {
  if (!$('#contact_name').val()) { return }
  if (!$('#contact_phone').val()) { return }
  if (!$('#contact_message').val()) { return }
  if (($('#contact_phone').val()).length != 10) { return }

  return true;
}

$(document).on("input", "input#contact_phone.form-control.contacts__input.validateContactform.number_phone", function(){
  if (($('#contact_phone').val()).length == 10 || ($('#contact_phone').val()).length == 0) {
    document.getElementById("number_phone_warning").style.display = "none";
  } else {
    document.getElementById("number_phone_warning").style.display = "block";
  }
});

require("@rails/actiontext")


function animateValue(id, start, end, duration) {
  if (start === end) return;
  var range = end - start;
  var current = start;
  var increment = end > start? 1 : -1;
  var stepTime = Math.abs(Math.floor(duration / range));
  var obj = document.getElementById(id);
  var timer = setInterval(function() {
      current += increment;
      obj.innerHTML = current;
      if (current == end) {
          clearInterval(timer);
      }
  }, stepTime);
}


function animateValueCustom(id, start, end, duration) {
  if (start === end) return;
  var range = end - start;
  var current = start;
  var increment = 20 //change increment if needed
  var stepTime = Math.abs(Math.floor(duration / range));
  var obj = document.getElementById(id);
  var timer = setInterval(function() {
      current += increment;
      obj.innerHTML = current;
      if (current == end) {
          clearInterval(timer);
      }
  }, stepTime);
}

// Swipers

// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import SwiperCore, { Mousewheel, Pagination, Autoplay, Navigation } from 'swiper';
SwiperCore.use([Mousewheel, Pagination, Autoplay, Navigation]);


$(document).on('turbolinks:load', function () {

  const MainSwiper = new Swiper('.MainSwiper', {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    loop: true,
  });

  const Priozernuyswiper = new Swiper(".priozernuy-swiper", {
    navigation: {
      nextEl: ".project-arrow-next",
      prevEl: ".project-arrow-prev",
    },
  
    noSwiping: true,
    autoHeight: true,
    noSwipingClass: 'swiper-slide',
    spaceBetween: 52,
  });

  const Aboutswiper = new Swiper(".AboutSwiper", {
    pagination: {
      el: ".swiper-pagination-years",
      dynamicBullets: true,
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 2013) + '</span>';
      },
    },
    spaceBetween: 10,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    initialSlide: 2,
  });

  $('#event_2016_link_1').click(function(){ 
    $('.event_2016_img').hide();
    $('#event_2016_img_1').show();
  });

  $('#event_2016_link_2').click(function(){ 
    $('.event_2016_img').hide();
    $('#event_2016_img_2').show();
  });

  $('#event_2016_link_3').click(function(){ 
    $('.event_2016_img').hide();
    $('#event_2016_img_3').show();
  });

  $('#event_2017_link_1').click(function(){ 
    $('.event_2017_img').hide();
    $('#event_2017_img_1').show();
  });

  $('#event_2017_link_2').click(function(){ 
    $('.event_2017_img').hide();
    $('#event_2017_img_2').show();
  });

  $('#event_2017_link_3').click(function(){ 
    $('.event_2017_img').hide();
    $('#event_2017_img_3').show();
  });

  $('#event_2017_link_4').click(function(){ 
    $('.event_2017_img').hide();
    $('#event_2017_img_4').show();
  });

  $('#event_2018_link_1').click(function(){ 
    $('.event_2018_img').hide();
    $('#event_2018_img_1').show();
  });

  $('#event_2018_link_2').click(function(){ 
    $('.event_2018_img').hide();
    $('#event_2018_img_2').show();
  });

  $('#event_2018_link_3').click(function(){ 
    $('.event_2018_img').hide();
    $('#event_2018_img_3').show();
  });

  $('#event_2019_link_1').click(function(){ 
    $('.event_2019_img').hide();
    $('#event_2019_img_1').show();
  });

  $('#event_2019_link_2').click(function(){ 
    $('.event_2019_img').hide();
    $('#event_2019_img_2').show();
  });

  $('#event_2021_link_1').click(function(){ 
    $('.event_2021_img').hide();
    $('#event_2021_img_1').show();
  });
  
  $('#event_2021_link_2').click(function(){ 
    $('.event_2021_img').hide();
    $('#event_2021_img_2').show();
  });

  $('#event_2021_link_3').click(function(){ 
    $('.event_2021_img').hide();
    $('#event_2021_img_3').show();
  });

  $('#event_2021_link_4').click(function(){ 
    $('.event_2021_img').hide();
    $('#event_2021_img_4').show();
  });

  $('#event_2022_link_1').click(function(){ 
    $('.event_2022_img').hide();
    $('#event_2022_img_1').show();
  });

  $('#event_2022_link_2').click(function(){ 
    $('.event_2022_img').hide();
    $('#event_2022_img_2').show();
  });
});

  
